<template>
  <div id="calendar" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5">Calendarios</v-row>
      <v-row no-gutters justify="end" class="mr-12 mb-4">
        <v-col
          cols="12"
          lg="6"
          md="5"
          sm="4"
          xs="12"
          class="selectEmpresa"
          align-self="end"
        >
          <v-row class="ml-12">
            <label for="enterprise" class="ml-5 mr-2">Empresa Pagadora</label>
            <select
              name="enterprise"
              id="enterprise"
              v-model="enterprise"
              @change="calendariosEmpresa()"
            >
              <option selected disabled value="0">Selecciona Empresa</option>
              <option
                v-for="enterprise in enterprises"
                :key="enterprise.id"
                v-bind:value="enterprise.id"
              >
                {{ enterprise.razonSocial }}
              </option>
            </select>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="5" xs="12">
          <v-card-title>
            <router-link to="/addCalendar" id="nuevoCalendario">
              <button class="breakSearchE botonAmarillo">
                Alta de Calendario
              </button>
            </router-link>
            <v-text-field
              class="mr-2"
              v-model="search"
              append-icon="search"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row no-gutters class="datos pa-0" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="calendars"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Calendarios por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5, 10],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias',
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.id }}</td>
                  <td class="text-sm-center">{{ item.nombre }}</td>
                  <td class="text-sm-center">
                    <span
                      v-if="item.lunesCatalogoId == 1"
                      class="activado fa-2x"
                    >
                      <font-awesome-icon icon="check-circle" />
                    </span>
                    <span
                      v-if="item.lunesCatalogoId == 2"
                      class="inactivo fa-2x"
                    >
                      <font-awesome-icon icon="times-circle" />
                    </span>
                    <span
                      v-if="item.lunesCatalogoId == 3"
                      class="pendiente fa-2x"
                    >
                      <font-awesome-icon icon="clock" />
                    </span>
                  </td>
                  <td class="text-sm-center">
                    <span
                      v-if="item.martesCatalogoId == 1"
                      class="activado fa-2x"
                    >
                      <font-awesome-icon icon="check-circle" />
                    </span>
                    <span
                      v-if="item.martesCatalogoId == 2"
                      class="inactivo fa-2x"
                    >
                      <font-awesome-icon icon="times-circle" />
                    </span>
                    <span
                      v-if="item.martesCatalogoId == 3"
                      class="pendiente fa-2x"
                    >
                      <font-awesome-icon icon="clock" />
                    </span>
                  </td>
                  <td class="text-sm-center">
                    <span
                      v-if="item.miercolesCatalogoId == 1"
                      class="activado fa-2x"
                    >
                      <font-awesome-icon icon="check-circle" />
                    </span>
                    <span
                      v-if="item.miercolesCatalogoId == 2"
                      class="inactivo fa-2x"
                    >
                      <font-awesome-icon icon="times-circle" />
                    </span>
                    <span
                      v-if="item.miercolesCatalogoId == 3"
                      class="pendiente fa-2x"
                    >
                      <font-awesome-icon icon="clock" />
                    </span>
                  </td>
                  <td class="text-sm-center">
                    <span
                      v-if="item.juevesCatalogoId == 1"
                      class="activado fa-2x"
                    >
                      <font-awesome-icon icon="check-circle" />
                    </span>
                    <span
                      v-if="item.juevesCatalogoId == 2"
                      class="inactivo fa-2x"
                    >
                      <font-awesome-icon icon="times-circle" />
                    </span>
                    <span
                      v-if="item.juevesCatalogoId == 3"
                      class="pendiente fa-2x"
                    >
                      <font-awesome-icon icon="clock" />
                    </span>
                  </td>
                  <td class="text-sm-center">
                    <span
                      v-if="item.viernesCatalogoId == 1"
                      class="activado fa-2x"
                    >
                      <font-awesome-icon icon="check-circle" />
                    </span>
                    <span
                      v-if="item.viernesCatalogoId == 2"
                      class="inactivo fa-2x"
                    >
                      <font-awesome-icon icon="times-circle" />
                    </span>
                    <span
                      v-if="item.viernesCatalogoId == 3"
                      class="pendiente fa-2x"
                    >
                      <font-awesome-icon icon="clock" />
                    </span>
                  </td>
                  <td class="text-sm-center">
                    <span
                      v-if="item.sabadoCatalogoId == 1"
                      class="activado fa-2x"
                    >
                      <font-awesome-icon icon="check-circle" />
                    </span>
                    <span
                      v-if="item.sabadoCatalogoId == 2"
                      class="inactivo fa-2x"
                    >
                      <font-awesome-icon icon="times-circle" />
                    </span>
                    <span
                      v-if="item.sabadoCatalogoId == 3"
                      class="pendiente fa-2x"
                    >
                      <font-awesome-icon icon="clock" />
                    </span>
                  </td>
                  <td class="text-sm-center">
                    <span
                      v-if="item.domingoCatalogoId == 1"
                      class="activado fa-2x"
                    >
                      <font-awesome-icon icon="check-circle" />
                    </span>
                    <span
                      v-if="item.domingoCatalogoId == 2"
                      class="inactivo fa-2x"
                    >
                      <font-awesome-icon icon="times-circle" />
                    </span>
                    <span
                      v-if="item.domingoCatalogoId == 3"
                      class="pendiente fa-2x"
                    >
                      <font-awesome-icon icon="clock" />
                    </span>
                  </td>
                  <td class="text-sm-center">
                    {{
                      item.calendarioSucursales.length > 0 ? "Sucursales" : ""
                    }}
                    {{ item.calendarioAreas.length > 0 ? "Areas" : "" }}
                  </td>
                  <td class="justify-center layout px-0">
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="editItem(item)"
                      >fa-edit</v-icon
                    >
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="viewItem(item)"
                      >fa-eye</v-icon
                    >
                    <v-icon color="red" small @click="temporal(item)"
                      >fa-trash-alt</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </v-container>
    <!--Aceptación de acciones-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              advice = false;
              id = '';
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="deleteItem()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Tipo de vacante</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--DIALOG DE DATOS-->
    <v-dialog v-model="dialog" scrollable max-width="370px">
      <v-card>
        <v-card-title class="orange white--text"
          >Información de Calendario</v-card-title
        >
        <v-card-text>
          <div class="form-group mt-5">
            <label style="display: inline-block; width: 80px"
              >Calendario:</label
            >
            {{ dateSelect.nombre }}
          </div>
          <div class="form-group">
            <label style="display: inline-block; width: 80px"
              >Descripción:</label
            >
            {{ dateSelect.descripcion }}
          </div>
          <div
            class="form-group"
            v-if="dateSelect.calendarioSucursales.length > 0"
          >
            <label style="display: inline-block; width: 80px">Aplica a:</label>
            <li
              v-for="suc in dateSelect.calendarioSucursales"
              :key="suc.nombreSucursal"
            >
              {{ suc.nombreSucursal }}
            </li>
          </div>
          <div class="form-group" v-if="dateSelect.calendarioAreas.length > 0">
            <label style="display: inline-block; width: 80px">Aplica a:</label>
            <li
              v-for="area in dateSelect.calendarioAreas"
              :key="area.nombreArea"
            >
              {{ area.nombreArea }}
            </li>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      headers: [
        {
          text: "Id",
          align: "center",
          value: "id",
        },
        {
          text: "Calendario",
          align: "center",
          value: "nombre",
        },
        {
          text: "Lunes",
          align: "center",
          value: "lunes",
        },
        {
          text: "Martes",
          align: "center",
          value: "martes",
        },
        {
          text: "Miercoles",
          align: "center",
          value: "miercoles",
        },
        {
          text: "Jueves",
          align: "center",
          value: "jueves",
        },
        {
          text: "Viernes",
          align: "center",
          value: "viernes",
        },
        {
          text: "Sabado",
          align: "center",
          value: "sabado",
        },
        {
          text: "Domingo",
          align: "center",
          value: "domingo",
        },
        {
          text: "Aplica a ",
          align: "center",
          value: "calendarioSucursales.length",
        },
        {
          text: "Editar  /   Ver  / Eliminar",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      calendars: [],
      dateSelect: {
        id: 1,
        nombre: "Produccion",
        dias: {
          lunes: 1,
          martes: 1,
          miercoles: 1,
          jueves: 1,
          viernes: 1,
          sabado: 3,
          domingo: 2,
        },
        descripcion: "Descripcion de produccion",
        calendarioSucursales: [],
        calendarioAreas: [],
      },
      respuesta: "",
      advice: false,
      id: "",
      confirmation: false,
      date: "",
      edit: false,
      vacio: false,
      enterprises: [],
      enterprise: localStorage.empresaIdGlobal,
    };
  },
  methods: {
    editItem(item) {
      localStorage.idCalendar = item.id;
      this.$router.push("/editCalendar");
    },
    /*
      temporal sirve para mostrar el nombre del dato a eliminar en la Alerta ayuda al usuario a no borrar el equivocado
      guarda solo el id del dato
    */
    temporal(item) {
      this.id = item.id;
      this.respuesta =
        "¿ Seguro que desea eliminar el elemento " + item.nombre + " ?";
      this.advice = true;
    },
    deleteItem() {
      this.advice = false;
      this.respuesta = "";
      this.show = true;
      axios
        .delete(Server + "/calendarios/" + this.id, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.id = "";
          this.respuesta = "El calendario fue eliminado con éxito";
          this.show = false;
          this.confirmation = true;
          this.calendariosEmpresa();
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.id = "";
            this.respuesta = "El calendario no fue eliminado";
            this.confirmation = true;
          }
        });
    },
    viewItem(item) {
      console.log(item);
      this.dateSelect = item;
      this.dialog = true;
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    calendariosEmpresa() {
      this.show = true;
      axios
        .get(Server + "/calendarios/por-empresa/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.calendars = response.data;
          this.show = false;
        })
        .catch((e) => {
          //console.log(response);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.listar();
    this.calendariosEmpresa();
  },
};
</script>